<template>
  <div style="position: relative;">
    <shift-item :shift="assignedShifts[0]" :workplan="workplan" />
    <v-menu offset-y v-if="assignedShifts.length > 1" :close-on-content-click="false">
      <template #activator="{attrs, on}">
        <div class="others" v-bind="attrs" v-on="on">+{{ assignedShifts.length - 1 }}</div>
      </template>
      <v-card>
        <v-card-text class="pa-2">
          <shift-item
            v-for="(shift, index) in assignedShifts.slice(1)"
            class="other"
            :shift="shift"
            :workplan="workplan"
            :key="shift.id"
          />
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: "TimeaxisItem",
    components: {
      ShiftItem: () => import("./ShiftItem")
    },
    props: {
      workplan: Object
    },
    computed: {
      assignedShifts() {
        const shifts = this.$helpers.cloneDeep(this.workplan.assignedShifts);
        if (shifts.length > 0) {
          return shifts.map(shift => {
            switch (shift.shiftType) {
              case this.$enums.SHIFT_TYPES.FIX:
                shift.icon = this.workplan.leaveRequest
                  ? "mdi-airplane"
                  : this.workplan.breakDay
                  ? "mdi-bed"
                  : "mdi-clock";
                shift.color = this.workplan.leaveRequest
                  ? this.workplan.leaveRequest.leaveType.color
                  : this.workplan.breakDay
                  ? "orange"
                  : shift.color;
                break;
              case this.$enums.SHIFT_TYPES.FLEXIBLE:
                shift.icon = this.workplan.leaveRequest
                  ? "mdi-airplane"
                  : this.workplan.breakDay
                  ? "mdi-bed"
                  : "mdi-timelapse";
                shift.color = this.workplan.leaveRequest
                  ? this.workplan.leaveRequest.leaveType.color
                  : this.workplan.breakDay
                  ? "orange"
                  : shift.color;
                break;
              case this.$enums.SHIFT_TYPES.OVERTIME:
                shift.icon = this.workplan.breakDay ? "mdi-bed" : "mdi-account-convert";
                break;
            }
            return shift;
          });
        }
        return [
          {
            id: "1",
            shiftType: "OFF_DAY",
            name: this.workplan.countAsOvertime
              ? this.$t("time.calendar.holiday")
              : this.workplan.breakDay
              ? this.$t("time.calendar.breakday")
              : this.$t("time.calendar.off_day"),
            icon: this.workplan.countAsOvertime ? "mdi-star" : this.workplan.breakDay ? "mdi-bed" : "mdi-briefcase-off",
            color: this.workplan.countAsOvertime ? "#0288D1" : this.workplan.breakDay ? "#999" : "#333"
          }
        ];
      }
    }
  };
</script>

<style lang="scss" scoped>
  .others {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--v-primary-base);
    position: absolute;
    color: #fff;
    top: -10px;
    right: -10px;
    box-shadow: -1px 1px 2px 1px #a97d70;
    font-weight: 500;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
